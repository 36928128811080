// custom typefaces

import "./src/normalize.css"
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
import "@fontsource-variable/montserrat"
import "@fontsource/merriweather"
import "./src/style.scss"
import "prismjs/themes/prism-tomorrow.css";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import netlifyIdentity from 'netlify-identity-widget';

export const onClientEntry = () => {
    netlifyIdentity.init();
};
